//import { Image, Images } from "./Image";
import { Link } from "react-router-dom";
import { Theme } from "~/Theme";
import { Plugin } from "~/Plugin";
import { Panel } from "./Panel";
import {} from "@firebase/util";
import { Router } from "~/Router";
import { Generation } from "~/Generation";
import { TopBar } from "~/App/TopBar";

export function ForgotPassword() {
  const navigate = Router.useNavigate();
  const [email, setEmail] = useState("");
  const [success, setSucces] = useState(false);
  const [loading, isLoading] = useState(false);
  const { resetPassword } = Plugin.get();
  const showErrorSnackbar = Generation.Image.Exception.Snackbar.showMessage();

  const submit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    sendPasswordResetEmail();
  };

  const sendPasswordResetEmail = async () => {
    if (!resetPassword) throw new Error("Plugin not found");
    try {
      if (!success) {
        isLoading(true);
        await resetPassword({ email: email });
        setSucces(!success);
      }
    } catch (error) {
      console.log(error);
      const exception = Generation.Image.Exception.create(
        error ?? "Invalid Credintials",
      );
      showErrorSnackbar(exception, "center", "top", "error");
    } finally {
      isLoading(false);
    }
  };
  return (
    <>
      <div className="bg-brand-50 absolute left-0 top-0 flex h-screen w-screen flex-col items-center overflow-y-auto overflow-x-hidden text-white">
        <TopBar />
        <div className="bg-brand-50 z-0 flex flex-col justify-center overflow-hidden p-4 md:p-20 xl:max-w-[1348px]">
          {" "}
          <div className="mx-auto flex max-w-[40rem] flex-col gap-5">
            <form className="flex w-full basis-1/4 p-2" onSubmit={submit}>
              <Panel className="bg-brand-100 relative flex w-full flex-col gap-[24px] rounded-[12px]">
                <div className="mx-auto flex w-11/12 flex-col gap-5">
                  {/* <div className="text-center text-3xl font-semibold text-white">
                    Alchemy Studio
                  </div> */}
                  {!success && (
                    <>
                      <div className="gap-1 text-center text-2xl">
                        Forgot Your Password?
                      </div>
                      <div className="text-center text-sm text-slate-500">
                        Enter your email address and we will send you instructions to
                        reset your password.
                      </div>
                      <Theme.Label>Email</Theme.Label>
                      <Theme.Input
                        value={email}
                        type="email"
                        onChange={setEmail}
                        onKeyDown={(event) => event.key === "Enter" && submit}
                        placeholder="Email"
                        className="h-[60px]"
                      />
                      <Theme.Button
                        color="brand"
                        className="h-[60px] w-full"
                        loading={loading}
                      >
                        Continue
                      </Theme.Button>
                      <div className="text-center text-sm font-semibold text-slate-500">
                        Go back to{" "}
                        <Link
                          className="text-sm text-[#009EFF] hover:underline hover:underline-offset-4"
                          to="/"
                        >
                          Alchemy Studio
                        </Link>
                      </div>
                    </>
                  )}

                  {success && (
                    <>
                      <div className="gap-1 text-center text-2xl">Check Your Email</div>
                      <div className="text-center text-sm text-slate-500">
                        Please check the email address {email} for instructions to reset
                        your password.
                      </div>
                      <div className="flex justify-center">
                        <Theme.Icon.MailCheck size="50" color="#5eead4" />
                      </div>
                      <Theme.Button
                        size="lg"
                        color="secondary"
                        fullWidth
                        className="h-[44px]"
                        onClick={() => setSucces(!success)}
                        loading={loading}
                        //                            disabled={!url}
                      >
                        Resend email
                      </Theme.Button>
                      <div className="text-center text-sm font-semibold text-slate-500">
                        Go back to{" "}
                        <Link
                          className="text-sm text-[#009EFF] hover:underline hover:underline-offset-4"
                          to="/"
                        >
                          Alchemy Studio
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
