import { RandomImage } from "~/LandigPage/RandomImage";

export function LeftBanner() {
  const randomIndexArray = RandomImage.randomIntFromInterval();
  const rows = useMemo(
    () =>
      randomIndexArray.map((value, index) => {
        return (
          <div>
            <img
              className={classes(
                "h-52 w-52 rounded hover:scale-150 hover:cursor-zoom-in",
                (index == 4 || index == 12 || index == 20) && "ml-10",
              )}
              alt=""
              src={RandomImage.loginImages[value]}
            />
          </div>
        );
      }),
    [],
  );

  return <div className="z-[0] -ml-10 flex flex-wrap gap-1">{rows}</div>;
}
