//import { Image, Images } from "./Image";
import { Plugin } from "~/Plugin";
import { Theme } from "~/Theme";
import { Panel } from "./Panel";
import * as AlchemyStudio from "@stability/stablestudio-plugin";
import { Router } from "~/Router";
import { Generation } from "~/Generation";
import { Link } from "react-router-dom";
import { TopBar } from "~/App/TopBar";
import { LeftBanner } from "~/Login/LeftBanner";

export function Signup() {
  const [email, setEmail] = useState("");
  const navigate = Router.useNavigate();
  const { signUp } = Plugin.get();
  const [password, setPassword] = useState("");
  const [loading, isLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const showSnackBar = Generation.Image.Exception.Snackbar.showMessage();
  const deviceType = Theme.useDevice();

  const submit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    createAccount();
  };

  const createAccount = async () => {
    if (!signUp) throw new Error("Plugin not found");
    const userData: AlchemyStudio.UserInfo = {
      email: email,
      password: password,
    };
    try {
      isLoading(true);
      if (!email) throw new Error("Email is mandatory");
      if (!password) throw new Error("Password is mandatory");
      const response = await signUp({ user: userData });
      if (response) {
        response?.emailVerified && navigate("/generate");
      }

      showSnackBar("Email verification link sent", "center", "top", "success");
      navigate("/login");
    } catch (error) {
      console.log(error);
      const exception = Generation.Image.Exception.create(error);
      showSnackBar(exception, "center", "top", "error");
    } finally {
      isLoading(false);
    }
  };

  const leftBanner = useMemo(() => {
    return deviceType == "large" && <LeftBanner />;
  }, [deviceType]);

  return (
    <>
      <div className="bg-brand-50 absolute left-0 top-0 flex h-screen w-screen flex-col items-center overflow-y-auto overflow-x-hidden">
        <TopBar />
        <div className="bg-brand-50 z-0 flex flex-row justify-between overflow-hidden md:max-w-[1348px]">
          {leftBanner}
          <div className="p-4 md:pl-10">
            <form className="flex flex-col gap-5 pt-4" onSubmit={submit}>
              <div className="flex flex-row gap-4">
                <Theme.Logo className="h-10 w-10 rounded" />
                <div className="font-inter text-whitesmoke xxsm:text-2xl text-left md:text-3xl">
                  Welcome to Alchemy Studio
                </div>
              </div>
              <Panel className="bg-brand-100 relative flex w-full flex-col gap-6 rounded-lg p-5">
                <div className="text-whitesmoke mx-auto flex flex-col gap-5 md:w-[374px]">
                  <Theme.Label>Email</Theme.Label>
                  <Theme.Input
                    value={email}
                    type="email"
                    onChange={setEmail}
                    onKeyDown={(event) => event.key === "Enter" && submit}
                    placeholder="Email"
                    className="h-[44px]"
                  />
                  <Theme.Label>Password</Theme.Label>
                  <div className="flex flex-row">
                    <Theme.Input
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={setPassword}
                      onKeyDown={(event) => event.key === "Enter" && submit}
                      className="h-[44px]"
                      placeholder="••••••••"
                    />
                    <div
                      className="dark:placeholder:text-muted-white bg-brand-50 flex h-[44px] text-black shadow-sm focus:border-transparent focus:outline-none dark:border-none dark:text-white"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <Theme.Icon.Eye className="self-center opacity-50" />
                      ) : (
                        <Theme.Icon.EyeOff className="self-center opacity-50" />
                      )}
                    </div>
                  </div>

                  <Theme.Button
                    color="brand"
                    loading={loading}
                    label="Sign In"
                    className="font-inter text-whitesmoke h-[44px] w-full text-base font-medium"
                  >
                    Continue
                  </Theme.Button>
                  <div className="text-center text-sm font-semibold text-slate-500">
                    Login to Alchemy studio{" "}
                    <Link
                      className="text-sm text-[#009EFF] hover:underline hover:underline-offset-4"
                      to="/login"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </Panel>
              <div className="relative w-[374px] text-center text-sm text-slate-500">
                By signing up, you agree to our Terms of Service and Privacy Policy
                Agreement.
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
