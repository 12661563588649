import { Sidebar } from "./Sidebar";
import { UploadImage } from "./UploadImage";

export declare namespace Image {
  export { Sidebar, UploadImage };
}

export namespace Image {
  Image.Sidebar = Sidebar;
  Image.UploadImage = UploadImage;
}
