import { Theme } from "~/Theme";
import { Wallet } from "..";
import { Plugin } from "~/Plugin";
import { Generation } from "~/Generation";
import { App } from "~/App";
import { UIMessage } from "~/UIMessage";

export function CreateWallet({ onClose }: { onClose: () => void }) {
  const { createWallet } = Plugin.get();
  const showSnackBar = Generation.Image.Exception.Snackbar.showMessage();
  const [loading, isLoading] = useState(false);
  const { address, mnemonic } = Wallet.use();
  const accessToken = App.getToken();

  //Get wallet info from backend
  useEffect(() => {
    const createNewWallet = async (): Promise<void> => {
      if (createWallet && !address) {
        isLoading(true);
        try {
          if (!accessToken) throw new Error(UIMessage.MSG_NO_TOKEN);
          const newWallet = await createWallet({ accessToken });
          Wallet.add({ ...newWallet });
          showSnackBar("Wallet created successfully", "center", "top", "success");
        } catch (error) {
          showSnackBar(UIMessage.ERR_SETUP_WALLET, "center", "top", "error");
          throw error;
        } finally {
          isLoading(false);
        }
      }
    };
    createNewWallet();
  }, [address]);

  return (
    <>
      <div
        className={classes(
          "bg-brand-100 font-inter relative z-[1] flex grow flex-col justify-center gap-4 overflow-hidden rounded-lg p-4",
        )}
      >
        <>
          <span className="text-whitesmoke p-2 text-2xl">
            Your wallet has been created
          </span>
          <div className="items-ceneter flex flex-row gap-2 px-2">
            <Theme.Icon.Info size={20} className="opacity-75" />
            <span className="text-whitesmoke text-sm opacity-50">
              See Wallet information below
            </span>
          </div>
          <div className="bg-brand-50 flex min-h-[200px] flex-row flex-col gap-3 rounded-lg p-8">
            <span className="text-whitesmoke text-2xl font-semibold">
              Your Bittensor Wallet Mnemonic
            </span>
            <span className="text-whitesmoke text-sm opacity-50">
              <p>
                Your mnemonic is a unique set of words that can be used to restore your
                wallet and access your funds.
              </p>
              <p className="py-4">
                It's extremely important that you keep this mnemonic safe and secure!
              </p>
            </span>
            <span className="text-whitesmoke text-2xl font-semibold opacity-100">
              IMPORTANT SECURITY TIPS:
            </span>
            <span className="text-whitesmoke gap-y-4 text-sm opacity-50">
              <p>
                - Write down your mnemonic on a piece of paper and store it in a secure
                location.
              </p>
              <p>
                {" "}
                - Never share your mnemonic with anyone, even trusted parties or
                TensorAlchemy staff.{" "}
              </p>
              <p>
                - Do not store your mnemonic digitally or online unless it's encrypted
                and backed up securely.
              </p>
              <p>
                - Your mnemonic is the only way to restore your wallet if you lose
                access to it.
              </p>
              <p className="py-4">
                If you lose your mnemonic, you will permanently lose access to your
                funds and wallet.
              </p>
              <p className="py-4">Treat it like you would treat any valuable asset.</p>
            </span>

            {loading ? (
              <Theme.Loading.Spinner className="absolute top-1/2 z-[2] h-16 w-16 self-center" />
            ) : (
              <span className="text-whitesmoke text-sm">{mnemonic}</span>
            )}
            <span className="text-whitesmoke gap-y-4 text-sm opacity-50">
              <p className="py-4">
                Press the ’Confirm’ button below to acknowledge that you have securely
                stored your mnemonic.
              </p>
            </span>
            <p className="pt-4 font-semibold text-white">
              After doing so you will **not** see your mnemonic again!
            </p>
          </div>
          <Theme.Button
            color="brand"
            className="h-11 w-64"
            iconLeft={<Theme.Icon.CheckCircle size={20} />}
            onClick={onClose}
          >
            Confirm
          </Theme.Button>
        </>
      </div>
    </>
  );
}
