import { GlobalState } from "~/GlobalState";

export type Count = number;
export namespace Count {
  export function Button() {
    const [count, setCount] = Count.use();
    const max = 1;
    const min = 1;

    return (
      <div
        className="bg-brand-200 rounded xxsm:w-full sm:w-full lg:w-56 h-[44px] flex flex-row space-x-3 justify-between p-2
        text-base font-inter items-center"
      >
        <div className="flex flex-row space-x-1">
          <img
            className="relative h-6 overflow-hidden shrink-0"
            alt=""
            src="ImageCounter.svg"
          />
          <div className="relative">Image count</div>
        </div>
        <div
          className="cursor-not-allowed"
          onClick={() => {
            if (count > min) setCount(count - 1);
          }}
        >
          -
        </div>
        <div>{count}</div>
        <div
          className="cursor-not-allowed"
          onClick={() => {
            if (count < max) setCount(count + 1);
          }}
        >
          +
        </div>
      </div>
    );
  }
}

export namespace Count {
  export const preset = () => 1 as const;

  export const get = (): number => store.getState().count;
  export const set = (count: number) => store.getState().setCount(count);
  export const use = () =>
    store(({ count, setCount }) => [count, setCount] as const, GlobalState.shallow);
}

const store = GlobalState.create<{
  count: number;
  setCount: (count: number) => void;
}>((set) => ({
  count: Count.preset(),
  setCount: (count) => set({ count }),
}));
