import { Router } from "~/Router";
import { Theme } from "~/Theme";
import { Sidebar, Sidebars } from "./Sidebar";
import { TopBar } from "./TopBar";
import { Generation } from "~/Generation";
import { useLocation } from "react-router-dom";
import { State } from "./State";
import { GlobalState } from "~/GlobalState";
import { Plugin } from "~/Plugin";
import * as ReactQuery from "@tanstack/react-query";
import { Wallet } from "~/Wallet";
import { Verification } from "~/Verification";

export function App() {
  const isMobileDevice = Theme.useIsMobileDevice();
  const path = useLocation().pathname;
  const displaySideBar = path?.startsWith("/generate");
  const { getAuthToken, getWalletInfo } = Plugin.get();
  const showSnackBar = Generation.Image.Exception.Snackbar.showMessage();

  // //Store auth token/address in zustand state
  const fetchAddressWithToken = async () => {
    if (!getAuthToken) throw Error("Plug in not found");
    //Get auth token
    const token = await getAuthToken();
    if (token) {
      App.setToken(token);
    }
    if (!!token) {
      //Update IDP status in async mode
      Verification.updateIdpState(token);
    }
    //Get wallet address
    if (!!token && getWalletInfo) {
      Wallet.updateWalletState(token);
    }
    return { data: {} };
  };

  //Query client for getRounds call
  const { isLoading } = ReactQuery.useQuery({
    enabled: true,
    queryKey: ["App.Token"],
    queryFn: fetchAddressWithToken,
    staleTime: 0,
    cacheTime: 0,
  });

  return useMemo(
    () => (
      <>
        {isLoading ? (
          <div className="bg-brand-50 flex h-screen w-screen flex-col items-center justify-center">
            <Theme.Logo className="max-w-[64px] animate-ping self-center" />
          </div>
        ) : (
          <div className="bg-brand-50 selection:bg-brand-1000 absolute left-0 top-0 flex h-screen w-screen flex-col items-center overflow-y-auto overflow-x-hidden text-white">
            {/* <Shortcut.Palette /> */}
            <TopBar />
            <div className="bg-brand-50 relative flex w-screen shrink grow flex-col gap-10 p-4 xl:p-12 2xl:max-w-[1348px] 2xl:px-24">
              {displaySideBar && (
                <div className="bg-brand-100 relative box-border flex w-full self-center rounded-xl p-6 text-left sm:overflow-x-auto md:max-w-[1348px]">
                  <div className="flex w-full flex-col overflow-visible">
                    <Sidebars />
                    <Generation.Image.Create.Result />
                  </div>
                </div>
              )}
              <div
                className={classes(
                  "relative box-border flex w-full grow self-center rounded-xl text-left sm:overflow-x-auto md:max-w-[1348px] md:p-4",
                  displaySideBar && "bg-brand-100",
                )}
              >
                <div className="flex min-h-0 grow flex-col">
                  <div className="relative shrink grow overflow-visible rounded-lg">
                    {/* <div className="flex flex-col relative rounded-lg shrink grow min-h-0 [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.05),_rgba(44,_181,_205,_0.05))] overflow-y-auto" /> */}
                    <Router />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {isMobileDevice && <BottomBar />} */}
      </>
    ),
    [isMobileDevice, displaySideBar, isLoading],
  );
}

export declare namespace App {
  export { Sidebar, TopBar, State };
}

export namespace App {
  App.Sidebar = Sidebar;
  App.TopBar = TopBar;
  App.State = State;

  export const setToken = (value: string) => {
    State.get().set(value);
  };

  export const getToken = (): string | null =>
    State.use(({ accessToken }) => accessToken, GlobalState.shallow);
}
