import { Link, useLocation } from "react-router-dom";
import { Theme } from "~/Theme";

export const Left = () => {
  const path = useLocation().pathname;
  const generation = path?.startsWith("/generate");
  return (
    <>
      <Link className="flex flex-row items-center justify-start gap-2" to="/">
        <Theme.Logo className="h-10 w-10" />
        <div className="font-inter text-whitesmoke invisible relative text-left text-lg md:visible">
          {generation ? "Alchemy Studio" : "Human Validation"}
        </div>
      </Link>
    </>
  );
};
