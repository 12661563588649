import { Verification } from ".";
import { GlobalState } from "../GlobalState/index";

export type State = {
  idpData: Verification.IdpData;
  set: (idpData: Verification.IdpData) => void;
};

export namespace State {
  const store = GlobalState.create<State>((set) => ({
    idpData: {},
    set: (value) =>
      set((state) => ({
        idpData: { ...state.idpData, ...value },
      })),
  }));

  export const get = store.getState;
  export const use = store;
}
