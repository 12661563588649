export namespace Random {
  export const get = (exclude: string[] = [], triesLeft = 10): string => {
    const prompt = list[Math.floor(Math.random() * list.length)] as string;
    return triesLeft > 0 && exclude.find((excluded) => excluded === prompt)
      ? get(exclude, triesLeft - 1)
      : prompt;
  };

  export const is = (prompt?: string): boolean => !!prompt && list.includes(prompt);

  const list = [
    "Stunning sunset over a futuristic city, with towering skyscrapers and flying vehicles, golden hour lighting and dramatic clouds, high detail, moody atmosphere",
    "Mystical forest with glowing mushrooms and a babbling brook, surrounded by towering trees and shrouded in mist, ethereal, dreamlike, stylized",
    "Majestic dragon, perched atop a cliff overlooking a fiery landscape, with smoke and ash rising into the air, intense, detailed, scales, dynamic, epic",
    "Serene beach scene with crystal clear water and white sand, tropical palm trees swaying in the breeze, perfect paradise, seascape",
    "Post-apocalyptic wasteland with rusted and abandoned vehicles, dust storms and towering dust clouds, gritty, dark, dramatic, apocalyptic, stylized",
    "Mystical underwater world with vibrant coral and exotic sea creatures, sun beams shining through the water, mysterious, magical, otherworldly",
    "Snowy winter wonderland with a lone cabin in the distance, surrounded by frosty trees and fresh snowfall, peaceful, serene, detailed, winter landscape",
    "Mysterious and abandoned temple in the jungle, surrounded by lush vegetation and tall trees, atmospheric, ancient, moody",
    "Vibrant and bustling city street with busy traffic, bright lights, and towering skyscrapers, chaotic, fast-paced, cityscape, high detail",
    "Gothic cathedral in a stormy night, with lightning illuminating the sky and rain pouring down, dramatic, atmospheric, high detail, moody",
    "Fantasy castle on a hilltop, surrounded by rolling hills and a beautiful sunset, magical, serene, high detail, romantic, stylized",
    "Vast desert with sand dunes and a lone oasis in the distance, hot sun and clear blue sky, peaceful, serene, desertscape, high resolution",
    "Ethereal aurora borealis over a snowy mountain range, with a full moon shining in the background, mystical, peaceful, serene, winter landscape, high detail",
    "Giant robots fighting in a futuristic city, with buildings falling and explosions all around, intense, fast-paced, dramatic, stylized, futuristic",
    "Medieval market square with vendors selling goods, colorful banners, and bustling crowds, lively, busy, historic, high detail, architectural",
    "Abandoned prison on a cliff, with a stormy sea below, eerie, moody, atmospheric, architectural, stylized",
    "Beautiful waterfall in a lush jungle, with sunlight shining through the trees, serene, peaceful, tropical, jungle landscape, high detail",
    "Surreal carnival scene with bright lights, strange creatures, and a full moon, dreamlike, vibrant, stylized, surreal, high detail",
    "Breathtaking mountain range with a clear river running through it, surrounded by tall trees and misty clouds, serene, peaceful, mountain landscape, high detail",
    "Historic battlefield with armies clashing and smoke rising, intense, fast-paced, dramatic, historic, high detail",
    "Enchanted forest with glowing fireflies and a babbling brook, surrounded by towering trees and shrouded in mist, magical, ethereal, dreamlike, stylized",
    "Sunken ship in a vibrant coral reef, with schools of colorful fish swimming by, mysterious, magical, high detail",
    "Futuristic city with towering skyscrapers and flying vehicles, set against a vibrant sunset, futuristic, serene, high detail",
    "Volcanic island with a boiling crater and ash clouds rising into the air, intense, dramatic, natural disaster, high detail, volcanic landscape",
    "Lonely lighthouse on a rocky coast during a storm, with waves crashing and lightning flashing, moody, atmospheric, seascape, high detail",
    "Ruined castle on a cliff overlooking the sea, with a stormy sky and crashing waves, eerie, moody, atmospheric",
    "Vibrant street fair with colorful stalls and bustling crowds, lively, busy, high detail",
    "Mysterious underground cave with glowing crystals and an underground river, dark, mysterious, underground landscape, high detail",
    "Glorious sunrise over a cityscape, with the city slowly coming to life and the sky turning orange and pink, serene, peaceful, cityscape, high detail",
    "Breathtaking view of a snowy mountain range, with crisp clear air and a brilliant blue sky, serene, peaceful, majestic, high detail, winter landscape",
    "Enchanting waterfall in a lush jungle, surrounded by exotic plants and wildlife, tranquil, serene, high detail, tropical landscape",
    "Glowing aurora borealis over a frozen lake, with towering mountains in the distance, ethereal, magical, winter landscape, high detail",
    "Rushing rapids in a crystal clear river, surrounded by towering trees and lush vegetation, energetic, serene, high detail, river landscape",
    "Vibrant flower field in full bloom, surrounded by rolling hills and a brilliant blue sky, colorful, serene, high detail, spring landscape",
    "Glimpses of a herd of wild elephants crossing a savanna, surrounded by tall grass and a brilliant orange sunset, majestic, peaceful, high detail, safari landscape",
    "Tranquil pond surrounded by tall trees, with a beautiful lily pad garden and calm reflection of the sky, serene, peaceful, high detail, water landscape",
    "Giant redwoods in a misty forest, with towering trees and lush vegetation, peaceful, serene, high detail, forest landscape",
    "Breathtaking view of a desert landscape, with towering sand dunes and a brilliant blue sky, serene, vast, high detail, desert landscape",
    "Stunning sunset over an ocean horizon, with orange and pink hues spreading across the sky, peaceful, serene, high detail, seascape",
    "Massive airship floating above a sprawling metropolis, with towering skyscrapers and busy streets below, futuristic, detailed, high resolution, urban landscape",
    "Steampunk submarine exploring a coral reef, surrounded by exotic sea creatures and vibrant coral, detailed, surreal, steampunk style",
    "Medieval castle on a cliff, surrounded by a moat and rolling green hills, majestic, medieval, high detail",
    "Towering robot statue in a desolate wasteland, with dust storms and abandoned vehicles in the distance, mechanical, dystopian, high detail, post-apocalyptic landscape",
    "Massive statue of a dragon in a lush jungle, surrounded by exotic plants and tall trees, mysterious, ancient, high detail, tropical landscape",
    "Ancient temple in a mountain range, surrounded by misty clouds and tall peaks, mysterious, ancient, high detail",
    "Crashed spaceship in a dense forest, surrounded by tall trees and exotic vegetation, futuristic, detailed, high detail, sci-fi landscape",
    "Lighthouse on a stormy beach, surrounded by crashing waves and dramatic clouds, intense, detailed, high detail, coastal landscape",
    "Steam locomotive in a snowy mountain range, surrounded by tall peaks and crisp clear air, nostalgic, detailed, high detail, winter landscape",
    "Old western town in the desert, surrounded by towering sand dunes and a brilliant blue sky, nostalgic, detailed, high detail, western landscape",
    "Futuristic cyborg, sleek metal enhancements and glowing circuits, standing in high-tech laboratory, intense, detailed, high resolution, sci-fi portrait",
    "Medieval knight, armor and shining sword, standing on battle-scarred battlefield, intense, detailed, high detail, portrait",
    "Powerful sorceress, flowing robes and mystical staff, standing in dark and ominous forest, mysterious, detailed, high detail, fantasy portrait",
    "Rogue adventurer, backpack and rugged appearance, standing in dense jungle, adventurous, detailed, high detail, portrait",
    "Wise wizard, long beard and mysterious tome, standing in dimly lit library, wise, detailed, high detail, portrait",
    "Daring astronaut, space suit and helmet, standing in front of futuristic spaceship, adventurous, detailed, high detail, portrait",
    "Skilled archer, bow and quiver of arrows, standing in forest clearing, intense, detailed, high detail, portrait",
    "Daring treasure hunter, map and compass, standing in desolate desert, adventurous, detailed, high detail, portrait",
    "Cosmic swirl of stars and galaxies, swirling in endless black void, otherworldly, abstract, high detail, space",
    "Psychedelic mandala of patterns and shapes, kaleidoscopic, trippy, detailed, abstract art",
    "Fractal landscape of geometric shapes and patterns, complex, intricate, abstract, digital art",
    "Glowing nebula of vibrant gas and dust, celestial, otherworldly, abstract, space art",
    "Hypnotic vortex of swirling colors, intense, detailed, abstract, digital art",
    "Aurora borealis, vibrant lights dancing in the night sky, ethereal, abstract, high detail, nature art",
    "Phantasmagoric carnival, carnival attractions shifting and changing, dreamlike, abstract, high detail, surreal art",
    "Radiant nebula, star clusters and gas clouds shining brightly, celestial, otherworldly, abstract, space art",
    "Iconic Parisian street with quaint cafes and bistros, charming, romantic, high detail, cityscape",
    "Golden hour New York City skyline, towering skyscrapers and bustling streets, iconic, dramatic",
    "Beautiful Venice canals with gondolas and bridges, charming, romantic, high detail, cityscape",
    "Majestic Machu Picchu, set against a backdrop of towering mountains, breathtaking, high detail, landscape",
    "Breathtaking view of the Grand Canyon, vast and awe-inspiring, high detail, landscape",
    "Charming Santorini island with pristine beaches and iconic white buildings, Mediterranean, high detail, seascape",
    "The iconic Great Wall of China, stretching along the countryside, historical, high detail, landscape",
    "Stunning view of the Sydney Opera House, with the harbor and cityscape in the background, iconic, high detail, cityscape",
    "The stunning Taj Mahal, set against a backdrop of lush greenery, historic, high detail, landmark",
    "Breathtaking view of the Serengeti with roaming wildlife, vast, high detail, nature landscape",
    "Giant rubber duck floating in the ocean with a small island on its back, surrounded by tropical palm trees and crystal clear water, bright and sunny day, calm seas, vivid colors, cinematic lighting, high detail",
    "Giant hamster wheel in the middle of a city, with skyscrapers and busy streets in the background, centralized, low details, stylized graphics, night time, lit up, neon lights, no shadows",
    "Humongous teacup and saucer floating in the sky, surrounded by clouds and rainbows, abstract, surreal, dreamlike, stylized oil painting style, vivid colors, detailed, high resolution, wide angled, otherworldly, fantastic",
    "Giant ice cream cone melting and creating a river through a city, with boats floating down it, dramatic, intense, chaotic, high detail, fast-paced, wide angled, aerial view, colorful, fun, stylized graphics",
    "Giant snail racing a car, high speed, intense, dynamic, detailed, cartoon style, wide angled, overhead view, vibrant colors, whimsical, absurd, surreal, fun",
    "A group of giant robots playing a game of soccer, intense, dynamic, high detail, 3D, stylized, futuristic, metallic, robots, absurd, fantastic, wide angled, overhead view, colorful, fun",
    "A city built entirely out of food, colorful, detailed, stylized, fun, absurd, vivid, delicious, overhead view, centralized, wide angled, vibrant, fantastical",
    "Giant fruit and vegetable parade, with various different fruits and vegetables marching down a city street, colorful, detailed, stylized, absurd, fun, vivid, delicious, overhead view, centralized, wide angled",
    "Giant caterpillar riding a bicycle, surreal, absurd, whimsical, stylized, detailed, vivid, high resolution, centralized, overhead view, colorful, fun",
    "A bustling city made entirely of candy, with gumdrop buildings and sugar-coated streets, bright colors, whimsical, playful, detailed",
    "A floating city in the clouds, with airships docking at sky-high platforms and clouds serving as roads, futuristic, whimsical, high-altitude, detailed",
    "An underground city, filled with steam-powered trains, strange creatures, and intricate tunnels and cave systems, dark, detailed, subterranean, steampunk",
    "A jungle city, with vines and roots serving as roads and buildings made of leaves, colorful, detailed, natural, tropical",
    "A city made of ice, with ice slides, frozen rivers, and snow-covered buildings, winter, magical, detailed, cold",
    "A pirate port, with ships setting sail, blacksmiths crafting weapons, and treasure-filled caves, adventurous, detailed, historic, swashbuckling",
    "A space station, with spaceships coming and going, astronauts on EVA missions, and maintenance robots hard at work, futuristic, high-tech, detailed, intergalactic",
    "A dragon's lair, with dragons hoarding treasure, sleeping on piles of gold, and shooting fire from their nostrils, mythical, detailed, adventurous, fantastical",
    "Bowl of steaming hot ramen with a perfect egg in the center, surrounded by thin slices of meat, green onions, and nori, with a flavorful broth and perfect noodles, high detail, focused on texture and steam",
    "Delectable pizza with melted cheese, juicy tomato sauce, and an array of toppings including pepperoni, mushrooms, and black olives, served hot and fresh, high resolution, stylized, focused on ingredients and melted cheese",
    "Sizzling hot sirloin steak with a perfect crust, seared to perfection and topped with herbs and spices, served with a side of roasted vegetables and mashed potatoes, juicy, delicious, high detail",
    "Assorted fruit platter with ripe, juicy strawberries, sweet grapes, tangy citrus, and juicy watermelon, set on a bed of greens and accented with mint leaves, high resolution, vibrant, natural",
    "Chocolate cake with rich, fudgy frosting and perfectly layered cake, garnished with fresh berries and drizzled with melted chocolate, decadent, sweet, high detail, food photography",
    "Baked salmon fillet with a perfectly crispy skin and tender, flaky flesh, served with a side of steamed vegetables and quinoa, healthy, flavorful, high detail, food photography",
    "Bowl of hearty chili with tender chunks of beef, rich tomato sauce, and a mix of spices, topped with grated cheddar cheese and green onions, high detail, focused on texture and heat, comfort food",
    "Platter of sushi rolls with colorful and flavorful ingredients, including avocado, tuna, salmon, and crab, arranged with precision and beauty, high resolution, Asian-style, focused on texture and color",
    "Stuffed bell peppers filled with tender ground beef, flavorful rice, and melted cheese, baked to perfection, juicy, flavorful, high detail, food photography",
    "Tasty tacos filled with seasoned beef, fresh salsa, melted cheese, and crunchy lettuce, served on a warm corn tortilla, Mexican-style, high resolution, focused on texture and flavor, food photography",
    "Contemporary living room with large windows overlooking a cityscape, neutral color palette, minimalistic design, sleek modern furniture, gallery wall of abstract art, warm lighting, high detail, open floor plan",
    "Rustic kitchen with exposed brick wall, reclaimed wood cabinetry, large farmhouse sink, industrial lighting fixtures, antique baking tools on open shelving, cast iron cookware, vintage accents, warm and inviting, detailed textures",
    "Luxurious bathroom with freestanding bathtub, rain shower, heated flooring, marble tiles, brass fixtures, floating vanity with double sink, elegant chandelier, high contrast lighting, spa-like atmosphere, high resolution",
    "Elegant dining room with crystal chandelier, dark wood table, velvet upholstered chairs, large statement art piece, tall windows with lush garden view, sophisticated color scheme, detailed textures, candlelit ambiance",
    "Industrial-style office with concrete floors, raw steel beams, large wooden desk, leather office chair, wall of bookshelves, minimalist design, warm lighting, high detail, organized and professional",
    "Cozy bedroom with four-poster bed, plush bedding, soft lighting, large windows with natural light, statement wallpaper, decorative throw pillows, high resolution textures, intimate and relaxing",
    "Glamorous dressing room with large mirror, Hollywood lights, plush velvet seating, glass shelves displaying designer shoes, hanging rods for clothes, detailed textures, high contrast lighting, organized and stylish",
    "Modern nursery with minimalistic design, white crib, rocking chair, wall mounted bookshelves, abstract art, neutral color palette, warm lighting, high detail, cozy and inviting",
    "Minimalistic home gym with rubber flooring, wall-mounted TV, weight bench, medicine ball, dumbbells, yoga mats, high-tech equipment, high detail, organized and efficient",
    "Traditional library with floor-to-ceiling bookcases, rolling ladder, large wooden desk, leather armchair, antique rug, warm lighting, high resolution textures, intellectual and inviting atmosphere",
    "Contemporary glass and steel building with sleek lines and an innovative facade, surrounded by an urban landscape, modern, high resolution",
    "Sleek and modern shopping center with an emphasis on natural light, an open-air interior, and eco-friendly features, contemporary, high detail, architectural renderings",
    "High-rise residential building with a unique form and facade, featuring terraces and stunning views, minimalist, high-end, architectural design",
    "Contemporary office building with a focus on sustainability, including green roofs and walls, modern, cutting-edge, architectural illustration",
    "Contemporary cultural center with a distinct form, featuring a vibrant public plaza and innovative exhibition spaces, futuristic, visually stunning, architectural drawings",
    "Stylish and modern apartment building with a clean, minimalist design and a focus on natural light, contemporary, high-end, architectural rendering",
    "Innovative mixed-use development featuring an interplay of form and function, with cutting-edge technology and sustainability features, contemporary, visually striking, architectural illustration",
    "Stylish and contemporary hotel with a unique form and facade, featuring luxury amenities and stunning views, modern, visually stunning",
    "Sleek and modern shopping mall with a focus on sustainable design, featuring natural light and innovative materials, contemporary, cutting-edge, architectural drawings",
    "Innovative and contemporary transportation hub with a unique form, featuring cutting-edge technology and sustainable features, modern, visually stunning, architectural illustration",
    "Race car with sleek design, captured in a high speed motion blur, dramatic lighting and shallow depth of field, motorsports, adrenaline, studio lighting",
    "Vintage hot rod with custom flame paint job, captured in low key lighting with selective focus on the chrome details, classic car, retro, high resolution",
    "Luxury sports car with aerodynamic curves, shot in a high contrast, high key lighting with shallow depth of field, exotic, detailed, sporty, studio lighting",
    "Majestic yacht with sleek lines, captured in a serene sunset light, with a shallow depth of field, boating, lifestyle",
    "Futuristic flying car with smooth lines, shot in a low light high contrast studio setting, science fiction, cutting edge, high detail, moody atmosphere",
    "Sturdy pickup truck, captured in a dramatic golden hour light with deep shadows, off-roading, rugged, dramatic, high resolution",
    "Vintage motorcycle with gleaming chrome and polished leather, captured in soft natural light with selective focus on the engine, classic, retro, detailed",
    "Luxury sports car with aggressive lines, shot in a high contrast, high key lighting with shallow depth of field, detailed, sporty, sleek, studio lighting",
    "Massive semi-truck with chrome details, captured in a high key lighting with shallow depth of field, transportation, commercial, detailed, high resolution",
    "Vintage sports car with classic curves, captured in a moody, low key light with selective focus on the grille, classic, retro, moody, detailed",
    "Oil painting of a bustling harbor town, with fishing boats, seagulls, and a lighthouse in the background, high contrast, dramatic lighting, heavily textured brushstrokes",
    "Watercolor painting of a rolling countryside, with fields of flowers, a red barn, and a white picket fence, soft and delicate brushstrokes, pastel colors",
    "Acrylic painting of a mountain landscape, with a stormy sky and a cabin nestled in the forest, high contrast, bold brushstrokes, high-resolution",
    "Oil painting of a sunset over the ocean, with orange and pink hues, gently rolling waves, and palm trees, heavily textured brushstrokes, dramatic lighting",
    "Pen and ink drawing of a cityscape, with tall skyscrapers and bustling city life, high-contrast, detailed linework, dramatic lighting",
    "Oil painting of a enchanted forest, with glowing mushrooms, fireflies, and a unicorn, soft brushstrokes, pastel colors, dream-like atmosphere",
    "Watercolor painting of a desert landscape, with sand dunes, mountains, and a blazing sun, soft and delicate brushstrokes, warm and vibrant colors",
    "Acrylic painting of a futuristic city, with neon lights, advanced technology, and flying cars, bold brushstrokes, high-contrast, highly stylized",
    "Oil painting of a tranquil lake surrounded by mountains, with a cabin on the shore, boats, and a sunset, heavily textured brushstrokes, warm and vibrant colors",
    "Pen and ink drawing of a mystical underwater world, with schools of fish, coral reefs, and a mermaid, highly detailed linework, high-contrast, stylized",
    "Watercolor painting of a rolling countryside, with fields of wheat, a red barn, and a white picket fence, soft and delicate brushstrokes, warm and vibrant colors",
    "Bronze statue of a powerful warrior, with a sword in hand, chiseled muscles, and a determined expression, highly detailed, dramatic lighting, intense gaze",
    "Marble statue of a serene goddess, with flowing robes, delicate features, and a tranquil expression, highly detailed, soft lighting, grace and beauty",
    "Wooden sculpture of a majestic animal, with intricate carving, textured fur, and piercing eyes, highly detailed, natural lighting, raw and powerful",
    "Stone statue of a mythological creature, with wings, horns, and a fierce expression, highly detailed, dramatic lighting, intense and otherworldly",
    "Bronze statue of a philosopher, with a wise expression, long beard, and a tome, highly detailed, soft lighting, introspective gaze",
    "Marble statue of a dancers, with fluid movements, intricate details, and grace, highly detailed, dramatic lighting, intense expression",
    "Wooden sculpture of a seascape, with waves, boats, and sea creatures, intricate carving, textured surface, high-resolution, natural lighting",
    "Bronze statue of a king, with regal attire, a crown, and a stern expression, highly detailed, dramatic lighting, commanding presence",
    "Wooden sculpture of a tree, with intricate branches, textured bark, and a strong trunk, highly detailed, natural lighting, grounding presence",
  ];
}
