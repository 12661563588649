import { Theme } from "~/Theme";

export function ImageModal({
  open,
  imgUrl,
  onConfirm,
  onClose,
}: {
  open: boolean;
  imgUrl: string;
  onConfirm: () => void;
  onClose: () => void;
}) {
  return (
    <Theme.Modal modalName="Reset" open={open} onClose={onClose}>
      <Theme.Modal.Panel className="justify-center gap-5 p-4">
        <Theme.Modal.TopBar onClose={onClose} />
        <img
          src={imgUrl}
          className="h-max w-max md:max-h-[800px] md:w-[800px]"
          style={{ aspectRatio: 1 / 1 }}
        />
      </Theme.Modal.Panel>
    </Theme.Modal>
  );
}
