import { Theme } from "~/Theme";

export function Section(props: Section.Props) {
  const {
    title,
    children,
    className: classNames,
    collapsable,
    defaultExpanded,
    padding = Theme.Common.Size.preset(),
    button,
    icon,
    divider = true,
    onChange,
    leftLabel,
  } = props;
  const [expanded, setExpanded] = useState(!!defaultExpanded || !collapsable);

  const className = useMemo(
    () =>
      typeof classNames === "function"
        ? classNames({ ...props, expanded })
        : classNames,
    [classNames, props, expanded],
  );

  useEffect(() => {
    if (!defaultExpanded) return;
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const buttonProps = useMemo(
    () => ({ testsdfsadfasdfs: true, transparent: true, className: "p-0" }),
    [],
  );

  const buttonRendered = useMemo(
    () => (typeof button === "function" ? button(buttonProps) : button),
    [buttonProps, button],
  );

  return (
    <div
      className={classes(
        "flex flex-col last-of-type:border-b-0",
        divider && "border-b-1 border-b",
        className,
      )}
    >
      {(expanded || !collapsable) && children && (
        <div className={classes()}>{children}</div>
      )}
    </div>
  );
}

export namespace Section {
  export type Props = {
    title?: false | string;
    collapsable?: boolean;
    defaultExpanded?: boolean;
    padding?: Theme.Common.Size | "none";
    button?: React.ReactNode | ((props: Theme.Button.Props) => React.ReactNode);
    divider?: boolean;
    icon?: (props: Section.Props & { expanded: boolean }) => Theme.Icon.Prop;

    onChange?: (expanded: boolean) => void;
    className?: string | ((props: Section.Props & { expanded: boolean }) => string);
    children?: React.ReactNode;
    leftLabel?: false | string;
  };
}
