import { GlobalState } from "~/GlobalState";
import { State } from "./State";
import { UIMessage } from "~/UIMessage";
import { Plugin } from "~/Plugin";
export namespace Verification {
  /**
   * Type to hold verification status
   */
  export declare type IdpData = {
    status?: IdpStatus;
  };

  //Open IDP url in new tab
  export const openIdpTab = async (token?: string | null): Promise<void> => {
    const { verifyUser } = Plugin.get();
    if (!verifyUser) throw Error(UIMessage.ERR_PLUGIN_NOT_FOUND);
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    const url = await verifyUser({ accessToken: token });
    window?.open(url, "_blank")?.focus();
  };

  export const getLabel = (status: IdpStatus): string => {
    switch (status) {
      case IdpStatus.PENDING:
        return "Pending";

      case IdpStatus.FAILURE:
        return "Failed";
    }
    return "Proof of Humanity";
  };

  export const getToolTip = (status: IdpStatus): string => {
    switch (status) {
      case IdpStatus.PENDING:
        return "Your verification is pending";

      case IdpStatus.FAILURE:
        return "Verification failed try again";
    }
    return "Verify your identity to get paid";
  };

  /**
   * Possible IDP status from BE
   */
  export enum IdpStatus {
    NONE = "NONE",
    PENDING = "PENDING",
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
  }

  export namespace Idp {
    export const getIdpStatusFromString = (value: string): IdpStatus => {
      const arr = Object.values(IdpStatus).filter((item: string) => item == value);
      if (arr.length == 0) throw new Error(UIMessage.ERR_IDP_STATUS_NOT_FOUND);
      return arr[0];
    };
  }

  export const setStatus = (value: IdpData) => {
    State.get().set(value);
  };

  export const use = (): IdpStatus =>
    State.use(
      ({ idpData }) => (idpData.status ? idpData.status : IdpStatus.NONE),
      GlobalState.shallow,
    );

  export const updateIdpState = async (token: string) => {
    const { getVerificationStatus } = Plugin.get();
    if (getVerificationStatus) {
      //Get verification status of logged in user
      const status = await getVerificationStatus({ accessToken: token });
      //Set IDP status
      Verification.setStatus({
        status: Verification.Idp.getIdpStatusFromString(status),
      });
    }
  };
}
