import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

export namespace Snackbar {
  export const use = () => {
    const { enqueueSnackbar } = Theme.Snackbar.use();
    return useCallback(
      (exception: Generation.Image.Exception) =>
        enqueueSnackbar(exception.description, {
          variant: "error",
        }),
      [enqueueSnackbar],
    );
  };

  export const showMessage = () => {
    const { enqueueSnackbar } = Theme.Snackbar.use();
    return useCallback(
      (
        exception: Generation.Image.Exception | string,
        horizontal: "left" | "center" | "right",
        vertical: "top" | "bottom",
        variant: "success" | "error" | "info",
      ) =>
        enqueueSnackbar(
          typeof exception === "string" ? exception : exception.description,
          {
            variant: variant,
            anchorOrigin: {
              vertical: vertical,
              horizontal: horizontal,
            },
          },
        ),
      [enqueueSnackbar],
    );
  };
}
