import { useVirtualizer } from "@tanstack/react-virtual";
import { motion } from "framer-motion";
import { Generation } from "~/Generation";
import { GlobalState } from "~/GlobalState";
import { Delete } from "../../Images/Delete";
import { Download } from "../../Images/Download";
import { Modal } from "../../Images/Modal";
import { Scroll } from "../../Images/Scroll";
import { State } from "../../Images/State";
import { Output } from "./Output";

export type Images = Generation.Image[];

export function Result({ className }: Result.Props) {
  const latestGeneration = Generation.Image.Create.Latest.use();
  const placeholderOffset = !latestGeneration ? 1 : 0;

  const outputs = Generation.Image.Outputs.latest();
  const count = outputs.length + placeholderOffset;

  // const { isFetching, fetchPreviousPage, hasPreviousPage } = Query.use();

  const parentRef = useRef<HTMLDivElement>(null);
  const parentOffsetRef = useRef(0);
  const scrollDownIndexRef = useRef(-1);
  const scrollingDownRef = useRef(false);
  const heightsRef = useRef<{ [index: number]: number }>({});

  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  const virtualizer = useVirtualizer({
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => heightsRef.current[index] ?? 400,

    count,
    scrollMargin: parentOffsetRef.current,
    paddingEnd: 10,
    overscan: 4,
  });

  const virtualItems = virtualizer.getVirtualItems();

  const showTop = useMemo(
    () => (virtualItems[0]?.index ?? 0) > 3 && scrollDownIndexRef.current === -1,
    [virtualItems],
  );

  const onScrollToTop = useCallback(() => {
    scrollDownIndexRef.current = virtualizer.scrollOffset ?? 0;
    virtualizer.scrollToOffset(0, { behavior: "smooth" });
  }, [virtualizer]);

  const onScrollBack = useCallback(() => {
    scrollingDownRef.current = true;
    virtualizer.scrollToOffset(scrollDownIndexRef.current, {
      behavior: "smooth",
      align: "start",
    });
  }, [virtualizer]);

  useEffect(() => {
    if (scrollDownIndexRef.current !== -1 && scrollingDownRef.current) {
      virtualizer.scrollToOffset(scrollDownIndexRef.current);
      if (scrollDownIndexRef.current === virtualizer.scrollOffset) {
        scrollDownIndexRef.current = -1;
        scrollingDownRef.current = false;
      }
    }
  }, [virtualItems, virtualizer]);

  useEffect(() => {
    if (showTop) {
      scrollDownIndexRef.current = -1;
    }
  }, [showTop]);

  // useEffect(() => {
  //   const lastIndex = virtualItems[virtualItems.length - 1]?.index;
  //   lastIndex &&
  //     lastIndex >= outputs.length - 10 &&
  //     !isFetching &&
  //     hasPreviousPage &&
  //     fetchPreviousPage();
  // }, [
  //   virtualItems,
  //   outputs.length,
  //   hasPreviousPage,
  //   isFetching,
  //   fetchPreviousPage,
  // ]);

  const rows = useMemo(
    () =>
      virtualItems.map((virtualRow) => {
        const isPlaceholder = virtualRow.index === 0 && !!placeholderOffset;

        const outputID = outputs[virtualRow.index - placeholderOffset]?.id;
        if (!outputID && !isPlaceholder) return null;

        const layoutID = isPlaceholder ? Generation.Image.Outputs.nextID() : outputID;

        return (
          <div className="flex flex-col gap-1">
            <motion.div
              key={layoutID}
              data-index={virtualRow.index}
              ref={(element) => {
                virtualizer.measureElement(element);
                if (element)
                  heightsRef.current[virtualRow.index] =
                    element.getBoundingClientRect().height;
              }}
              layoutId={layoutID}
              layout="preserve-aspect"
              className={classes(
                "relative flex flex-col gap-4",
                virtualRow.index === count - 1 && "p-2",
              )}
              initial={{ opacity: 0, y: -300 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{
                type: "spring",
                stiffness: 500,
                damping: 50,
                mass: 2,
              }}
            >
              <Result.Output
                placeholder={isPlaceholder}
                divider={virtualRow.index !== 0}
                outputID={outputID}
              />
            </motion.div>
          </div>
        );
      }),
    [virtualizer, virtualItems, placeholderOffset, outputs],
  );

  if (!latestGeneration) return null;

  return (
    <>
      <Result.Modal />
      <Result.Scroll
        onScrollToTop={onScrollToTop}
        onScrollBack={onScrollBack}
        showTop={showTop}
        showBottom={scrollDownIndexRef.current !== -1}
        outputs={outputs}
      />
      <div
        ref={parentRef}
        className={classes(
          "relative flex grow flex-col gap-4 overflow-y-auto bg-brand-100",
          className,
        )}
      >
        <div className="pt-5">
          <div className="w-full relative rounded bg-brand-200 h-0.5" />
        </div>
        <div className="relative w-full" style={{ height: virtualizer.getTotalSize() }}>
          <div
            className="w-full gap-4 rounded-tr-none rounded-b-none overflow-hidden"
            // className="absolute top-0 left-0 w-full"
            style={{
              transform: `translateY(${
                (virtualItems[0]?.start ?? 0) - virtualizer.options.scrollMargin
              }px)`,
            }}
          >
            {rows}
          </div>
        </div>
      </div>
    </>
  );
}

export declare namespace Result {
  export { Delete, Download, Modal, State, Scroll, Output };
}

export namespace Result {
  Result.Delete = Delete;
  Result.Download = Download;
  Result.Modal = Modal;
  Result.State = State;
  Result.Scroll = Scroll;
  Result.Output = Output;

  export type Props = Styleable & { minimum?: number };
  export type Options = {
    input?: ID;
    project?: ID;
    sortOrder: "descending" | "ascending";
  };

  export const add = (images: Images) => State.get().addImages(images);

  export const use = (): Images =>
    State.use(({ images }) => Object.values(images), GlobalState.shallow);

  export const useFromIDs = (...ids: IDs): Images =>
    State.use(
      ({ images }) =>
        ids
          .filter((id) => id !== undefined)
          .map((id) => images[id as ID])
          .filter((image): image is Generation.Image => image !== undefined),
      GlobalState.shallow,
    );
}
