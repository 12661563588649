import { Theme } from "~/Theme";
import { Wallet } from "..";
import { Link } from "react-router-dom";

export function WalletInfo({
  address,
  walletUpdated,
  setUpWallet,
}: Wallet.WalletProps) {
  return (
    <>
      <div
        className={classes(
          "bg-brand-100 font-inter relative z-[1] flex grow flex-col justify-center gap-4 overflow-y-auto rounded-lg p-4",
        )}
      >
        <div className="flex flex-row items-center justify-between">
          <span className="text-whitesmoke p-2 text-2xl">
            {walletUpdated
              ? "Your wallet has been updated"
              : "Your wallet has been created"}
          </span>{" "}
          <Link
            className="cusrsor-pointer text-brand-1000 text-base font-semibold hover:underline hover:underline-offset-4"
            to="/validate"
          >
            {"Start Voting >>"}
          </Link>
        </div>
        <div className="items-ceneter flex flex-row gap-2 px-2">
          <Theme.Icon.Info size={20} className="opacity-75" />
          <span className="text-whitesmoke text-sm opacity-50">
            See your information below
          </span>
        </div>
        <div className="bg-brand-50 flex min-h-[200px] flex-row flex-col gap-3 rounded-lg p-8">
          <span className="text-whitesmoke text-2xl font-semibold">
            Human Validation Voting System
          </span>
          <span className="text-whitesmoke text-sm opacity-50">
            {walletUpdated ? (
              <p>Your wallet has been updated!</p>
            ) : (
              <p>Your Bittensor wallet has been successfully created!</p>
            )}
          </span>
          <span className="text-whitesmoke text-2xl font-semibold opacity-100">
            Wallet Information:{" "}
          </span>
          <span className="text-whitesmoke text-xl">Address : </span>
          <span className="text-whitesmoke gap-y-4 break-all text-sm">
            {" "}
            <p>{address}</p>
          </span>
          <span className="flextext-whitesmoke gap-y-4 text-sm opacity-50">
            <p className="py-4">
              This is your public address, which can be shared with others to receive
              funds or participate in the network.
            </p>

            <p className="text-xl">Important security tips:</p>
            <p className="pt-4">
              - Keep your coldkey safe and secure, ideally in a hardware wallet or
              offline storage.
            </p>
            <p>
              - Never share your coldkey with anyone, even trusted parties or Bittensor
              staff.
            </p>
            <p>
              - Back up your coldkey in multiple secure locations in case of loss or
              damage.
            </p>
            <p>- Monitor your address balance and transactions regularly.</p>
            <p className="pt-4">Ready to start earning on our TensorAlchemy network?</p>
            <p className="pt-4">Let's get voting!</p>
          </span>
        </div>
        {address && (
          <Theme.Button
            color="ternary"
            className="h-11"
            iconLeft={<Theme.Icon.Wallet size={20} />}
            onClick={setUpWallet}
          >
            Setup custom wallet
          </Theme.Button>
        )}
      </div>
    </>
  );
}
