import { useState } from "react";
import { Plugin } from "~/Plugin";
import { Round } from "./Round";
import * as ReactQuery from "@tanstack/react-query";
import { Theme } from "~/Theme";
import { App } from "~/App";
import { UIMessage } from "~/UIMessage";

// Main component
export function Validation() {
  const [currentRound, setCurrentRound] = useState<Round.RoundItem | null>(null);
  const { getRounds, submitvotes } = Plugin.get();
  const token = App.getToken();
  const [submitting, setSubmitting] = useState(false);
  const [expired, setExpired] = useState(false);
  const isMobileDevice = Theme.useIsMobileDevice();

  //Function to fetch rounds from backend
  const getRound = async () => {
    if (!getRounds) throw new Error("Plugin not found");
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    //    throw new Error("Testing sentry sourcemap");
    const response = await getRounds({ accessToken: token });
    if (!response) return;
    const round = response[0];
    return [{ ...round, votes_allowed: (round.votes_allowed ??= 3) }];
  };

  //Function to fetch first round from backend
  const fetchFirstround = async () => {
    const roundArr = await getRound();
    if (roundArr) {
      const result = { ...roundArr[0] };
      setCurrentRound(result);
    }
    return { data: currentRound };
  };

  //Query client for getRounds call
  const { isLoading, refetch, isFetching } = ReactQuery.useQuery({
    enabled: true,
    queryKey: ["Validation.Round"],
    queryFn: fetchFirstround,
    staleTime: 0,
    cacheTime: 0,
  });

  //Refetch round on expiry
  const restartVote = async () => {
    setExpired(false);
    refetch();
  };

  //Submit votes to backend
  const submitVotes = async (selected: Round.SelectedItems) => {
    if (!submitvotes) throw new Error("Plugin not found");
    if (!token) throw new Error(UIMessage.MSG_NO_TOKEN);
    setSubmitting(true);
    try {
      const submitRound = {
        ...currentRound,
        computes: currentRound?.computes?.filter(
          (item) => item.id && Object.keys(selected).includes(item.id),
        ),
      };
      //Consider submitvotes as optimistic transaction. In case of error it will be sent to sentry
      token && (await submitvotes({ ...submitRound, accessToken: token }));
    } finally {
      refetch();
      setSubmitting(false);
    }
  };

  const currentRoundComponent = useMemo(
    () =>
      currentRound?.computes?.length && currentRound?.computes?.length > 0 ? (
        <Validation.Round
          key={currentRound._id}
          round={currentRound}
          submitVoteHandler={submitVotes}
          loading={submitting}
          timeOuthandler={() => !expired && setExpired(true)}
        />
      ) : (
        !isLoading && (
          <Validation.Round
            key={"-1"}
            round={{ _id: "-1", message: currentRound?.message }}
            submitVoteHandler={() => {}}
            loading={submitting}
          />
        )
      ),
    [isLoading, submitting, isFetching, expired],
  );

  return (
    <>
      <div
        className={classes(
          "bg-brand-100 relative z-[1] flex grow flex-col items-center overflow-hidden rounded-lg py-4",
          (isFetching || submitting || isLoading || expired) &&
            "cursor-disable pointer-events-none cursor-not-allowed opacity-50",
          "min-h-[150px]",
        )}
      >
        {(isFetching || submitting || isLoading) && (
          <Theme.Loading.Spinner className="absolute top-[50%] z-[2] h-16 w-16 self-center" />
        )}

        {currentRoundComponent}
      </div>
      {expired && (
        <div
          className={classes(
            "absolute left-[40%] top-[30%] z-[2] flex flex-col gap-2",
            isMobileDevice && "left-[20%] top-[40%] break-all",
          )}
        >
          <Theme.Label
            className={classes(
              "bg-brand-50 rounded p-4 text-2xl text-white",
              isMobileDevice && "text-xl",
            )}
          >
            {UIMessage.ERR_ROUND_EXPIRED}
          </Theme.Label>
          <Theme.Button
            color="brand"
            iconLeft={<Theme.Icon.RefreshClockwise size={32} />}
            className="self-center"
            onClick={() => restartVote()}
          >
            Reload
          </Theme.Button>
        </div>
      )}
    </>
  );
}

export declare namespace Validation {
  export { Round };
}

export namespace Validation {
  Validation.Round = Round;
}
