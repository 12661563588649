export function SVG(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.48574 0.323291C4.66239 -0.107763 5.33761 -0.107764 5.51426 0.32329L6.68971 4.18178C6.74525 4.3173 6.86359 4.42416 7.01368 4.4743L9.64196 5.53566C10.1193 5.69516 10.1193 6.30484 9.64196 6.46434L7.01368 7.5257C6.86359 7.57584 6.74525 7.68269 6.68971 7.81822L5.51426 11.6767C5.33761 12.1078 4.66239 12.1078 4.48574 11.6767L3.31029 7.81822C3.25475 7.68269 3.13641 7.57584 2.98632 7.5257L0.358045 6.46434C-0.119348 6.30484 -0.119349 5.69516 0.358045 5.53566L2.98632 4.4743C3.13641 4.42416 3.25475 4.3173 3.31029 4.18178L4.48574 0.323291Z" />
    </svg>
  );
}
