import { Center } from "~/App/TopBar/Center";
import { Theme } from "~/Theme";
import { MobileLandingPage } from "./MobileLandingPage";
import { Router } from "~/Router";
import { RandomImage } from "./RandomImage";

export function LandingPage() {
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const navigate = Router.useNavigate();
  const isMobileDevice = Theme.useIsMobileDevice();

  const firstRow = useMemo(
    () =>
      RandomImage.firstRow.map((url, index) => {
        return (
          <li key={index}>
            <img
              className="h-[241px] w-[241px] rounded-sm hover:scale-150 hover:cursor-zoom-in"
              alt=""
              src={url}
            />
          </li>
        );
      }),
    [RandomImage.firstRow],
  );

  const secondRow = useMemo(
    () =>
      RandomImage.secondRow.map((url, index) => {
        return (
          <li key={index}>
            <img
              className="h-[241px] w-[241px]  hover:scale-150 hover:cursor-zoom-in"
              alt=""
              src={url}
            />
          </li>
        );
      }),
    [RandomImage.secondRow],
  );

  const mobileScreen = useMemo(
    () => (
      <>
        <MobileLandingPage />
      </>
    ),
    [isMobileDevice, firstRow, secondRow],
  );

  return (
    <>
      {isMobileDevice ? (
        mobileScreen
      ) : (
        <div className="bg-brand-50 absolute left-0 top-0 flex h-screen w-screen flex-col items-center overflow-y-auto overflow-x-hidden text-white">
          <div className="h-18 bg-brand-100 relative flex w-full shrink-0 justify-center">
            <div className="bg-brand-100 relative box-border flex w-full rounded-xl px-4 xl:px-12 2xl:max-w-[1348px] 2xl:px-24">
              <div className="flex flex-row items-center justify-start gap-2">
                <Theme.Logo className="h-10 w-10 rounded" />
                <div className="font-inter text-whitesmoke invisible relative text-left text-lg md:visible">
                  Alchemy Studio
                </div>
              </div>
              <Center />
              <div className="text-xm flex flex-row items-center justify-start gap-4 self-stretch p-1">
                <Theme.Button
                  color="ternary"
                  className="hover:bg-whitesmoke hover:text-brand-25 h-11 rounded border text-xs font-semibold"
                  label="Existing user"
                  labelPlacement="bottom"
                  onClick={() => navigate("/login")}
                >
                  Log in
                </Theme.Button>
                <Theme.Button
                  color="white"
                  className="text-brand-100 h-11 rounded border text-xs font-semibold"
                  label="New user"
                  labelPlacement="bottom"
                  onClick={() => navigate("/signup")}
                >
                  {" "}
                  Sign up
                </Theme.Button>
              </div>
            </div>
          </div>
          {/*  bg-[url('/main_asset.svg')] bg-no-repeat bg-center bg-cover */}
          <div className="flex w-screen flex-col items-center" />
          <div className="felx-col flex h-full w-full bg-[url('/main_asset.svg')] bg-center bg-no-repeat 2xl:bg-top">
            <div className="text-whitesmoke font-inter relative flex w-full flex-col items-center justify-start px-40 pt-10 text-center text-6xl">
              <div className="relative flex w-auto items-center leading-[72px] ">
                <span className="w-max xl:w-full">
                  <p className="m-0">AI Image Generator:</p>
                  <p className="justift-stretch flex flex-row">
                    <span>{`Turn `}</span>
                    <span className="text-brand-1000  px-3">text to images</span>
                    <span className="text-whitesmoke">in seconds</span>
                    {/* <span className="text-whitesmoke">in seconds</span> */}
                  </p>
                </span>
              </div>
              <div className="text-whitemoke relative text-lg opacity-75">
                Tensor Alchemy is an AI system that can create realistic images and art
                from a description in natural language.
              </div>
              <div className="font-inter flex flex-row items-start justify-center gap-5 pt-5 text-left text-base">
                <Theme.Button
                  color="brand"
                  className="border-brand-1100 h-11 w-44 rounded border-2 border-solid text-sm font-medium"
                  onClick={() => {
                    navigate("/signup");
                  }}
                  icon={
                    <img
                      className="h-5 w-5"
                      alt="AlchemyStudio"
                      src="stars-light-sparkle.svg"
                    />
                  }
                >
                  Get started
                </Theme.Button>

                <div className="bg-brand-200 font-inter border-lightgray flex h-11 flex-row items-center justify-center gap-2 rounded border px-4 py-2">
                  <div className="animate-text from-brand-1100  to-brand-1100  bg-gradient-to-r via-fuchsia-300 bg-clip-text text-sm text-transparent">
                    Join our Discord server!
                  </div>
                  <Theme.Button
                    color="white"
                    className="text-brand-100 h-7 rounded border text-sm font-semibold"
                    label="Join discord bot"
                    labelPlacement="bottom"
                    iconRight={<Theme.Icon.ArrowRight size={16} className="stroke-2" />}
                    onClick={() => {
                      openInNewTab("https://discord.gg/tensoralchemy");
                    }}
                  >
                    Join
                  </Theme.Button>
                </div>
              </div>
              <div className="ovrflow-hidden flex w-max flex-col justify-center gap-2 sm:pt-[200px] xl:pt-[370px] 2xl:pt-[400px]">
                <ul className="flex flex-row justify-center gap-3">{firstRow}</ul>
                <ul className="flex flex-row justify-center gap-3">{secondRow}</ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
