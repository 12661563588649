import { App } from "~/App";
import { Generation } from "~/Generation";
import { Theme } from "~/Theme";

export function Advanced({
  id,
  defaultExpanded,
  ...props
}: App.Sidebar.Section.Props & { id: ID }) {
  const { setInput, input } = Generation.Image.Input.use(id);
  const areModelsEnabled = Generation.Image.Models.useAreEnabled();
  const areSamplersEnabled = Generation.Image.Samplers.useAreEnabled();
  const isMobileDevice = Theme.useIsMobileDevice();

  const onScaleChange = useCallback(
    (cfgScale: number) => {
      setInput((input) => {
        input.cfgScale = cfgScale;
      });
    },
    [setInput],
  );

  const onStepsChange = useCallback(
    (steps: number) => {
      setInput((input) => {
        input.steps = steps;
      });
    },
    [setInput],
  );

  const onSeedNumberChange = useCallback(
    (seed: number) =>
      setInput((input) => {
        input.seed = seed;
      }),
    [setInput],
  );

  const onSeedChange = useCallback(
    (value: string) =>
      setInput((input) => {
        if (value === "") input.seed = 0;
      }),
    [setInput],
  );

  if (!defaultExpanded || !input) return null;
  return (
    <div className="flex flex-col gap-4">
      {isMobileDevice && areModelsEnabled && (
        <Generation.Image.Model.Dropdown id={id} />
      )}
      <div className="grid grid-cols-2 gap-4 justfify-between">
        <Theme.NumberInput
          icon={Theme.Icon.Steps}
          label="Generation steps"
          fullWidth
          number
          min={15}
          step={1}
          max={30}
          value={input?.steps}
          onNumberChange={onStepsChange}
          tooltip={
            <h1>
              Generation steps is how many times the image <br /> is sampled. More steps
              may be more accurate.
            </h1>
          }
        />
        <Theme.NumberInput
          icon={Theme.Icon.Percent}
          label="Guidance scale"
          placeholder="Auto"
          fullWidth
          number
          min={1}
          step={0.1}
          max={15}
          value={input?.cfgScale}
          onNumberChange={onScaleChange}
          tooltip={
            <h1>
              Guidance scale influences how closely
              <br /> the model follows the prompt.
            </h1>
          }
          placement="top"
        />
      </div>
      <div
        className={classes(
          (input?.init as any)?.src ? "grid-cols-2" : "grid-cols-1",
          "grid gap-4 justfify-between",
        )}
      >
        <Theme.NumberInput
          icon={Theme.Icon.Sprout}
          placeholder="Auto"
          inputClassName="w-full"
          label="Seed"
          fullWidth
          number
          min={1}
          step={1}
          max={4294967295}
          value={input.seed !== 0 ? input.seed : ""}
          onChange={onSeedChange}
          onNumberChange={onSeedNumberChange}
          iconRight={(props) =>
            input.seed !== 0 ? (
              <Theme.Tooltip content="Clear Seed">
                <Theme.Icon.X
                  {...props}
                  className={classes(
                    props.className,
                    "cursor-pointer duration-100 hover:opacity-100",
                  )}
                  onClick={() => onSeedNumberChange(0)}
                />
              </Theme.Tooltip>
            ) : null
          }
          tooltip={
            <h1>
              Seed determines the initial noise. Using the same seed with
              <br /> the same settings will create a very similar image.
            </h1>
          }
          placement="top"
        />
        {(input?.init as any)?.src && (
          <Theme.NumberInput
            icon={Theme.Icon.Percent}
            label="Prompt strength"
            placeholder="Auto"
            fullWidth
            number
            min={1}
            step={0.1}
            max={100}
            value={input.cfgScale}
            onNumberChange={onScaleChange}
            tooltip={
              <h1>
                Prompt strength determines how much the <br /> final image will portray
                your prompts.
              </h1>
            }
            placement="top"
          />
        )}
      </div>
      {!isMobileDevice && areModelsEnabled && (
        <div className="flex flex-col absolute bottom-0 w-full">
          <Generation.Image.Model.Dropdown id={id} />
        </div>
      )}
      {/* {areSamplersEnabled && (
          <div className="-mx-2">
            <Generation.Image.Sampler.Dropdown id={id} />
          </div>
        )} */}
    </div>
  );
}
