import { GlobalState } from "../GlobalState/index";

export type State = {
  accessToken: string | null;
  set: (accessToken: string) => void;
};

export namespace State {
  const store = GlobalState.create<State>((set) => ({
    accessToken: null,
    set: (value) =>
      set((state) => ({
        accessToken: value,
      })),
  }));

  export const get = store.getState;
  export const use = store;
}
