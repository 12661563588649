import { Login, User } from "~/Login";
import { Signup } from "~/Signup";
import { ForgotPassword } from "~/ForgotPassword";
import { App } from "~/App";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import { LandingPage } from "~/LandigPage";

export function AuthRouter() {
  const user = User.use();

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={<App />} path="*" />
      </Route>
      <Route element={user.emailVerified ? <App /> : <LandingPage />} path="/" />
      <Route element={<Login />} path="/login" />
      <Route element={<Signup />} path="/signup" />
      <Route element={<ForgotPassword />} path="/forgotpassword" />
    </Routes>
  );
}
