import ReactDOM from "react-dom/client";
import "~/GlobalVariables";
import { Providers } from "./App/Providers";
import { AuthRouter } from "./AuthRouter";
import * as Sentry from "@sentry/react";
const environment = import.meta.env.VITE_NODE_ENV;
const sentrydsn = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  dsn: sentrydsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  environment: environment,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api-dev.tensoralchemy\.ai\/api/,
    /^https:\/\/api.tensoralchemy\.ai\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const main = async () => {
  const root = document.getElementById("app");
  root &&
    ReactDOM.createRoot(root).render(
      <React.StrictMode>
        <Providers>
          <AuthRouter />
        </Providers>
      </React.StrictMode>,
    );
};

main();
