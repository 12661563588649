import { useEffect, useRef } from "react";

export namespace Hooks {
  export interface IUseInterval {
    (callback: () => void, interval: number): void;
  }

  //Hook for polling with an callback function and interval param
  export const useInterval: IUseInterval = (callback, interval) => {
    const savedCallback = useRef<(() => void) | null>(null);
    // After every render, save the latest callback into our ref.
    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(() => {
      function tick() {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }

      let id = setInterval(tick, interval);
      return () => clearInterval(id);
    }, [interval]);
  };
}
