import { useLocation } from "react-router-dom";
import { Generation } from "~/Generation";
import { Advanced } from "./Advanced";
import { Theme } from "~/Theme";

export function Sidebar() {
  const { input } = Generation.Image.Session.useCurrentInput();
  const location = useLocation();

  if (!input?.id) return null;
  return <Sidebar.Tab id={input.id} />;
}

export namespace Sidebar {
  export function Tab({
    id,
    variant = "generate",
  }: {
    id: ID;
    variant?: "generate" | "editor";
  }) {
    const [settingsOpen, setSettingsOpen] = useState(true);
    const areStylesEnabled = Generation.Image.Styles.useAreEnabled();
    const createDream = Generation.Image.Session.useCreateDream();
    const [advancedMode, setAdvancedMode] = useState(false);
    const { setInput, input } = Generation.Image.Input.use(id);
    const deviceType = Theme.useDevice();
    const isMobileDevice = Theme.useIsMobileDevice();

    const mode = () => {
      return useMemo(() => {
        return (
          <>
            <div className="flex flex-col lg:flex-row w-full grow shrink justify-between gap-2">
              <div className="flex flex-row w-full items-center">
                <img
                  className=" flex w-8 h-8 pr-1"
                  alt="AlchemyStudio"
                  src="stars-light-sparkle.svg"
                />
                <div className="relative text-lg font-inter text-whitesmoke text-left">
                  Alchemizer
                </div>
              </div>
              <div className="relative w-full lg:w-72 h-11 flex flex-row justify-start lg:justify-end ">
                <div className="self-stretch w-full rounded flex flex-row justify-center border-solid bg-brand-200 p-1 items-center">
                  <div
                    className={classes(
                      "self-stretch rounded w-full items-center justify-center py-1.5 px-3 z-[1]",
                      advancedMode ? "bg-brand-100" : "",
                    )}
                    onClick={() => {
                      setAdvancedMode(true);
                    }}
                  >
                    <div className="relative leading-[20px] cursor-pointer text-center">
                      Advanced
                    </div>
                  </div>
                  <div
                    className={classes(
                      "self-stretch rounded w-full items-center justify-center py-1.5 px-3 z-[1]",
                      !advancedMode ? "bg-brand-100" : "",
                    )}
                    onClick={() => {
                      setAdvancedMode(false);
                    }}
                  >
                    <div className="relative leading-[20px] cursor-pointer text-center">
                      Essential
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </>
        );
      }, [advancedMode]);
    };

    return (
      <div className="flex flex-col w-full grow shrink space-y-4">
        {mode()}
        <div className=" flex flex-row justify-between">
          {!isMobileDevice && (input?.init as any)?.src && (
            <div className="flex flex-col gap-2 h-full shrink-0 px-2">
              <Generation.Image.UploadImage.Display id={id} />
              <Generation.Image.UploadImage.Buttons.EditImage id={id} />
            </div>
          )}

          <div
            className={classes("flex flex-col justify-between gap-2 shrink grow w-2/3")}
          >
            {isMobileDevice && advancedMode && (
              <div>
                <Advanced id={id} defaultExpanded={advancedMode} />
              </div>
            )}

            {isMobileDevice && (input?.init as any)?.src && (
              <div className="flex flex-col relative gap-2 shrink-0 px-2">
                <Generation.Image.UploadImage.Display id={id} />
                <Generation.Image.UploadImage.Buttons.EditImage id={id} />
              </div>
            )}

            <Generation.Image.Prompt.Sidebar.Section
              id={id}
              advancedMode={advancedMode}
            />
            <div className="flex xxsm:flex-col lg:flex-row gap-2 pt-2 w-full justify-between">
              <div className="flex flex-col sm:flex-row gap-2">
                <Generation.Image.Count.Button />
                <Generation.Image.Size id={id} />
                {!(deviceType == "small") && !(input?.init as any)?.src && (
                  <Generation.Image.UploadImage.Buttons.AddImage id={id} />
                )}
              </div>
              <div className="flex flex-row gap-2 justify-between grow">
                {deviceType == "small" && !(input?.init as any)?.src && (
                  <Generation.Image.UploadImage.Buttons.AddImage id={id} />
                )}
                <Generation.Image.Prompt.Shuffle.Button inputID={id} index={0} />
                <Generation.Image.Create.Button
                  id={id}
                  onIdleClick={() => createDream()}
                  fullWidth
                  className={classes("hover:bg-brand-1100 h-[44px]")}
                />
              </div>
            </div>
          </div>
          {!isMobileDevice && advancedMode && (
            <div
              className={classes(
                "pl-2 relative flex flex-row",
                (input?.init as any)?.src ? "w-[300px]" : "w-[276px]",
              )}
            >
              <Advanced id={id} defaultExpanded={advancedMode} />
            </div>
          )}
          {/* </div>  */}
        </div>
      </div>
    );
  }
}
