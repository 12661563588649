import { Theme } from "~/Theme";
import { About } from "../About";
import { Router } from "~/Router";

export function AboutModal({
  open,
  onConfirm,
  onClose,
}: {
  open: boolean;
  imgUrl: string;
  onConfirm: () => void;
  onClose: () => void;
}) {
  const navigate = Router.useNavigate();
  return (
    <Theme.Modal
      modalName="Reset"
      open={open}
      onClose={onClose}
      className="selection:bg-brand-1000 select-text overflow-y-auto overflow-x-hidden sm:md:max-h-[100%] md:max-h-[90%] sm:lg:max-w-[100%] lg:max-w-[70%]"
    >
      <Theme.Modal.Panel className="relative justify-center gap-5 overflow-y-auto p-4">
        <Theme.Modal.TopBar onClose={onClose} className="h-3 p-0" />
        <About onStart={onClose} />
      </Theme.Modal.Panel>
    </Theme.Modal>
  );
}
