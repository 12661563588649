import { Theme } from "~/Theme";
import { Images } from "../Image";
import { ImageModal } from "../ImageModal";
import { AboutModal } from "..//AboutModal";
import { Router } from "~/Router";
import { Wallet } from "~/Wallet";
import { Hooks } from "~/Hooks";

export function Round({
  round,
  submitVoteHandler,
  timeOuthandler,
  loading,
}: Round.RoundProps) {
  const [selected, setSelected] = useState<Round.SelectedItems>({});
  const [imgUrl, setImgUrl] = useState<string>("");
  const isMobileDevice = Theme.useIsMobileDevice();
  const [openImgModal, setOpenImgModal] = useState(false);
  const [openAbtModal, setOpenAbtModal] = useState(false);

  const [timeRemaining, setTimeRemaining] = useState<number>(0);

  const { address } = Wallet.use();

  const navigate = Router.useNavigate();
  const votesAllowed = (round.votes_allowed ??= 3);

  // Show the actual time remaining for vote
  useEffect(() => {
    const currentTimestampInSec = Math.floor(Date.now() / 1000);
    const expiryTimeInSec = round.expiry
      ? Math.floor(round.expiry.getTime() / 1000)
      : 180;
    const initialTimeRemaining = expiryTimeInSec - currentTimestampInSec;
    setTimeRemaining(initialTimeRemaining);
  }, [round.expiry]);

  const clickHandler = useCallback(
    (computeId: string) => {
      // Removing a selected computeId from the selected list
      if (computeId in selected) {
        const { [computeId]: omitted, ...rest } = selected;
        return setSelected(rest);
      }

      // Only allow selecting {votesAllowed} items
      if (Object.keys(selected).length >= votesAllowed) {
        return selected;
      }
      const newSelected = { ...selected, [computeId]: true };
      // Auto-next if votesAllowed is only 1
      if (Object.keys(newSelected).length >= votesAllowed && votesAllowed < 2) {
        setSelected(newSelected);
        return submitVoteHandler(newSelected);
      }

      return setSelected(newSelected);
    },
    [selected],
  );

  const maximizeHandler = useCallback((computeId: string) => {
    if (round?.computes) {
      const computes = round?.computes.filter((compute) => compute.id == computeId);

      if (computes.length) {
        const { compute_url } = computes[0];

        if (compute_url) setImgUrl(compute_url);
      }

      setOpenImgModal(true);
    }
  }, []);

  const submitVisible = useMemo(() => {
    if ((votesAllowed && votesAllowed < 2) || Object.keys(selected).length == 0)
      return false;

    return Object.keys(selected).length >= votesAllowed;
  }, [selected]);

  const formatSeconds = (s: number) =>
    new Date(Math.max(s, 0) * 1000).toISOString().substr(14, 5);

  Hooks.useInterval(() => {
    if (timeRemaining < 0) return;

    const remainingSec = timeRemaining - 1;
    if (remainingSec < 0) {
      timeOuthandler && timeOuthandler();
    }

    setTimeRemaining(remainingSec);
  }, 1000);

  return (
    <>
      <Round.ImageModal
        open={openImgModal}
        onClose={() => setOpenImgModal(false)}
        onConfirm={() => setOpenImgModal(false)}
        imgUrl={imgUrl}
      />
      <Round.AboutModal
        open={openAbtModal}
        onClose={() => setOpenAbtModal(false)}
        onConfirm={() => setOpenAbtModal(false)}
        imgUrl={imgUrl}
      />
      <div
        className={classes(
          "flex w-full flex-row justify-between gap-1 px-6",
          isMobileDevice && "flex-col",
        )}
      >
        <div className="items-top flex shrink flex-row">
          <Theme.Tooltip placement="top" content={"No of images in this round"}>
            <div className="bg-brand-50 h-12 w-12 shrink items-center p-1 p-2 pr-2 text-center text-2xl">
              {round?.computes?.length.toString().padStart(2, "0")}
            </div>
          </Theme.Tooltip>
          <span className="text-whitesmoke p-2 text-sm opacity-50 md:p-4">
            Select the{" "}
            <b>
              best {votesAllowed} representation
              {votesAllowed > 1 && "s"}
            </b>{" "}
            of the prompt
          </span>
        </div>
        <div className="flex flex-row items-center gap-4">
          {submitVisible && (
            <Theme.Button
              fullWidth
              color="brand"
              label="Submit your selection"
              labelPlacement="left"
              disabled={loading}
              iconLeft={<Theme.Icon.Send size={16} />}
              className={classes("text-whitesmoke h-11")}
              onClick={() => submitVoteHandler(selected)}
            >
              Submit
            </Theme.Button>
          )}
          {!address && (
            <Theme.Button
              fullWidth
              color="brand"
              label="Create wallet"
              labelPlacement="right"
              iconLeft={<Theme.Icon.Wallet size={16} />}
              className={classes("text-whitesmoke h-11")}
              onClick={() => navigate("/wallet")}
            >
              Create Wallet
            </Theme.Button>
          )}
          {round._id !== "-1" && (
            <Theme.Tooltip
              placement="left"
              content={"Submit your vote before countdown"}
            >
              <Theme.Label className="bg-brand-50 items-center p-2 text-center text-2xl text-white">
                {formatSeconds(timeRemaining)}
              </Theme.Label>
            </Theme.Tooltip>
          )}
          <div
            className="bg-brand-50 h-10 w-10 items-center self-center rounded-full text-center text-xl hover:cursor-pointer"
            onClick={() => setOpenAbtModal(true)}
          >
            <Theme.Tooltip placement="left" content="How does it work?">
              <h1 className="text-whitesmoke pt-1 opacity-50">?</h1>
            </Theme.Tooltip>
          </div>
        </div>
      </div>
      {round._id !== "-1" ? (
        <div className="z-[0] flex shrink grow flex-col gap-1 p-4">
          <div className="text-whitesmoke font-inter bg-brand-50 ml-2 grow rounded p-4 text-sm md:text-base">
            <span className="pr-2 font-semibold">Prompt:</span>
            {round?.computes && round?.computes[0].prompt}
          </div>
          <div className="mb-4 ml-2 flex grow flex-row flex-wrap md:justify-start">
            {round.computes?.map((compute, idx) => (
              <Round.Images
                key={compute.id}
                disabled={loading}
                computeId={compute.id}
                imageUrl={compute.thumbnail_url}
                select={() => clickHandler(compute.id as string)}
                selected={selected[compute.id as string]}
                selectedCount={Object.keys(selected).length}
                maximize={() => maximizeHandler(compute.id as string)}
              ></Round.Images>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-whitesmoke flex min-h-[300px] flex-col p-4 text-center text-base font-semibold">
          {round.message}
        </div>
      )}
    </>
  );
}

export declare namespace Round {
  export { Images, ImageModal, AboutModal };

  export interface RoundItem {
    _id?: string;
    compute_ids?: string[];
    computes?: ComputeItem[];
    votes_allowed?: number;
    message?: string;
    expiry?: Date;
  }

  export interface ComputeItem {
    id?: string;
    prompt?: string;
    blob?: Blob;
    thumbnail_blob?: Blob;
    compute_url?: string;
    thumbnail_url?: string;
  }

  // Round component
  export interface RoundProps {
    round: RoundItem;
    submitVoteHandler: (selected: Round.SelectedItems) => void;
    timeOuthandler?: () => void;
    loading: boolean;
  }

  export interface SelectedItems {
    [computeId: string]: boolean;
  }
}

export namespace Round {
  Round.Images = Images;
  Round.ImageModal = ImageModal;
  Round.AboutModal = AboutModal;
}
