import { SnackbarProvider } from "notistack";
import { AuthProvider } from "~/AuthProvider/AuthProvider";
import { Environment } from "~/Environment";
import { Remote } from "~/Remote";
import { Router } from "~/Router";

export function Providers({ children }: React.PropsWithChildren) {
  return (
    <Environment.Provider>
      <Router.Provider>
        <AuthProvider>
          <SnackbarProvider>
            <Remote.Provider>{children}</Remote.Provider>
          </SnackbarProvider>
        </AuthProvider>
      </Router.Provider>
    </Environment.Provider>
  );
}
