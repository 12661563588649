import { Theme } from "~/Theme";

// Image component
interface ImageProps {
  computeId?: string;
  imageUrl?: string;
  disabled: boolean;
  selected: boolean;
  selectedCount: number;
  select: () => void;
  maximize: () => void;
}

function getOpacity({
  selectedCount,
  selected,
  disabled,
}: {
  selectedCount: number;
  selected: boolean;
  disabled: boolean;
}) {
  if (disabled) return "opacity-30";

  if (!selectedCount) return "opacity-100";

  if (!selected) return "opacity-100";

  return "opacity-50";
}

export function Images({
  computeId,
  imageUrl,
  selected,
  select,
  disabled,
  maximize,
  selectedCount,
}: ImageProps) {
  const opacity = getOpacity({ disabled, selected, selectedCount });
  const isMobileDevice = Theme.useIsMobileDevice();

  return (
    <div
      className={classes(
        "group relative min-w-[128px] max-w-[160px] flex-1 items-center p-1 md:max-w-[256px] xl:min-w-[256px] xl:max-w-[400px]",
        selected && "group-[.submit]-hover:bg-green-500 bg-opacity-50",
      )}
    >
      {selected && (
        <Theme.Icon.CheckCircle
          className={classes("text-brand-1000 absolute start-[40%] top-[40%]", opacity)}
          size={isMobileDevice ? 32 : 64}
        />
      )}
      <Theme.Icon.Maximize2
        className={classes(
          "absolute right-3 top-3 opacity-80",
          "hidden group-hover:block",
        )}
        onClick={maximize}
        size={20}
      />
      {imageUrl && (
        <img
          src={imageUrl}
          onClick={select}
          alt={`Image for ${computeId}`}
          className={`z-0 w-full rounded ${opacity}`}
        />
      )}
    </div>
  );
}
