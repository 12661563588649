export function Next() {
  return (
    <div className="flex items-center gap-1.5">
      {/* <Logo /> */}
      <div className="flex flex-col">
        <span className="text-3xl text-center text-pink-600 font-semibold">
          Alchemy Studio
        </span>
        <span className="-mt-1 text-xs font-light">
          <span className="text-white/75"> by </span>tensoralchemystudio.ai
        </span>
      </div>
    </div>
  );
}
