import { Router } from "~/Router";
import { Theme } from "~/Theme";

// Main component
export function About({ onStart }: { onStart: () => void }) {
  const isMobileDevice = Theme.useIsMobileDevice();
  const navigate = Router.useNavigate();
  const noImagesDiv = useMemo(
    () =>
      [1, 2, 3, 4, 5, 6].map((index) => {
        return (
          <div
            key={index}
            className={classes(
              "bg-brand-50 md: flex max-h-[128px] min-h-[128px] min-w-[128px] max-w-[128px]  items-center justify-center rounded-lg md:max-h-[200px] md:max-w-[200px]",
              index === 2 ? "bg-brand-1300" : "",
            )}
          >
            {index === 2 && <Theme.Icon.CheckCircle size={32} />}
          </div>
        );
      }),
    [],
  );
  return (
    <>
      <div
        className={classes(
          "font-inter relative z-[1] flex grow flex-col justify-center gap-4 overflow-hidden rounded-lg p-4",
        )}
      >
        <div className="bg-brand-100 flex flex-col gap-4 rounded-lg p-3">
          <span className="text-2xl">1. Create wallet</span>
          <span className="text-whitesmoke flex flex-row gap-2 pb-2 text-sm opacity-50">
            <Theme.Icon.Info size={18} />
            <p>
              If you would like to setup a custom wallet address, please click on the
              profile button in the top right, followed by "wallet"
            </p>
          </span>
        </div>
        <div className="bg-brand-100 flex flex-col gap-4 rounded-lg p-3">
          <span className="flex flex-row items-center gap-2 text-2xl">
            2. Proof of humanity <Theme.Icon.Info className="opacity-50" size={18} />
          </span>
          <span className="text-whitesmoke break-all text-sm opacity-50">
            To ensure the security of our data and prevent fraudulent activity, we
            require wallet addresses to be verified before processing any payouts. We
            will not pay out to unverified wallet addresses under any circumstances.
            <p className="py-2">
              When you submit your wallet address, it gets securely forwarded to our
              trusted verification partner. They will confirm that you are the
              legitimate owner of the wallet. This protects both you and us by making
              sure funds are only released to verified recipients.{" "}
            </p>
            <p className="py-2">
              Please note that while we need to collect some information during the
              verification process, we do not store or maintain access to your sensitive
              data. If you would like your verification data deleted at any point, you
              can submit a request to an admin and we will forward it to our
              verification provider to have your information promptly removed.
            </p>
          </span>
        </div>
        <div className="bg-brand-100 flex flex-col gap-4 rounded-lg p-3">
          <span className="text-2xl">3. How does it work?</span>
          <span className="text-whitesmoke flex flex-row gap-2 text-sm opacity-50">
            <Theme.Icon.Info size={18} />
            Select the best representation(s) of the prompt
          </span>
          <div className="bg-brand-50 rounded p-4 text-sm">
            <span className="font-semibold">{`Prompt : `} </span>
            Sleek and modern shopping mall with a focus on sustainable design, featuring
            natural
            <p>light and innovative materials.</p>
          </div>
          <div className="grid w-fit grid-cols-3 gap-2 self-center pt-2">
            {noImagesDiv}
          </div>
        </div>
      </div>
    </>
  );
}
