import { Theme } from "~/Theme";
import { Center } from "./Center";
import { Left } from "./Left";
import { Right } from "./Right";
import { User } from "~/Login";

export function TopBar() {
  const isMobileDevice = Theme.useIsMobileDevice();
  const user = User.use();

  return (
    <div className="h-18 bg-brand-100 relative z-[2] flex w-full shrink-0 justify-center">
      <div className="bg-brand-100 relative box-border flex w-full rounded-xl px-12 px-4 md:max-w-[1348px] 2xl:px-24">
        <Left />
        {user?.emailVerified && <Center />}
        {user?.emailVerified && <Right />}
      </div>
    </div>
  );
}

export declare namespace TopBar {
  export { Center };
}

export namespace TopBar {
  TopBar.Center = Center;

  export function Divider(props: Theme.Divider.Props) {
    return (
      <Theme.Divider
        variant="vertical"
        className="mx-2 group-hover:bg-red-500"
        {...props}
      />
    );
  }

  export function Buttons({ children }: React.PropsWithChildren) {
    return <div>{children}</div>;
  }

  export function Button({
    menu,
    ...props
  }: Theme.Button.Props & { menu?: React.ReactNode }) {
    return (
      <div
        className={classes(
          "group/button relative flex h-full items-center justify-center duration-150",
          props.active ? "dark:bg-brand-500" : "hover:dark:bg-muted-white-extra",
        )}
      >
        <Theme.Button transparent size="lg" className="p-4" {...props} />
        {menu && (
          <Theme.Button
            transparent
            size="sm"
            className="-ml-4 h-full p-0 pr-2"
            icon={Theme.Icon.ChevronDown}
          />
        )}
      </div>
    );
  }
}
