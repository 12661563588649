import { useLocation } from "react-router-dom";

import { Generation } from "~/Generation";

function getComponent(path: String) {
  if (path.startsWith("/generate")) {
    return <Generation.Image.Sidebar />;
  }

  return null;
}

export function Sidebars() {
  const location = useLocation();

  return <>{getComponent(location.pathname)}</>;
}
