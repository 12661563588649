export namespace UIMessage {
  //TODO: add all the UI message string here
  export const MSG_NO_TOKEN = "You do not have permission to access";
  export const MSG_ERR_SS58_FORMAT = "Not valid ss58 format";
  export const MSG_VALID_SS58_FORMAT = "Valid ss58 format";
  export const ERR_IDP_STATUS_NOT_FOUND = "Wrong IDP status";
  export const ERR_PLUGIN_NOT_FOUND = "Plugin not found";
  export const ERR_ROUND_EXPIRED = "This round has expired.";
  export const ERR_SETUP_WALLET = "Failed to setup wallet. Contact TensotAlchemy admin";
}
